import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import {Button} from 'react-bootstrap'
import {Link} from 'gatsby'
import {clickEvent} from "../../utils/events"

import styles from './PolicyCheck.module.css'

const PolicyCheck = () => {
  const [cookies, setCookie] = useCookies(["gdpr-cookie"])

  const [needToShow, updateNeedToShow] = useState(false) // need to use state so the component re-renders and destroys itself after policy is accepted

  function acceptPolicy() {
    console.log()
    setCookie("acceptedPolicy", true, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 180), //set expiration 180 days in future
    })
    updateNeedToShow(false)
  }

  useEffect(() => {
    setTimeout(() => {
      updateNeedToShow(!cookies.acceptedPolicy)
    }, 500)
  }, [cookies.acceptedPolicy])

  return (
    needToShow && (
      <div className={styles.policyCheckSection}>
        <div className={styles.policyCheckBox}>
          <p className={styles.policyText}>
            We use cookies to anonymously analyze site traffic. We do not track
            any personally identifiable information unless it is explicitly provided.{" "}
            <Link
              to="/privacy"
              onClick={() => clickEvent("Policy Check", "View Privacy Policy")}
            >
              Read our privacy policy and how we use cookies
            </Link>
            .
          </p>
          <p className={styles.policyText}>
            By using this site you consent to our use of cookies.
          </p>
          <div>
            <Button
              className="btn-gridfabric-outline"
              onClick={() => {
                clickEvent("Policy Check", "Accept Policy")
                acceptPolicy()
              }}
            >
              Accept Policy
            </Button>
          </div>
        </div>
      </div>
    )
  )

}

export default PolicyCheck