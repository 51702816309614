/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {CookiesProvider} from "react-cookie"

import PolicyCheck from '../PolicyCheck/PolicyCheck'

import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import styles from "./Layout.module.css"

import '../../utils/pageStyles.css'

const Layout = ({ children }) => {
  return (
    <CookiesProvider>
      <div className={styles.global}>
        <Header />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
        <PolicyCheck />
      </div>
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
