import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import {Link, useStaticQuery, graphql} from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { clickEvent } from "../../utils/events"

import styles from './Footer.module.css'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: contentfulWebsiteImages(imageId: { eq: "greenLogoSVG" }) {
        image {
          file {
            url
          }
        }
      }
    }
  `)

 return (
   <div className={styles.background}>
     <Container className={styles.footerContent}>
       <Row>
         <Col md={4}>
           <Row>
             <Col md={12} xs={6}>
               <Link to="/" className={styles.logo}>
                 <div>
                   <img
                     src={data.logo.image.file.url}
                     className={styles.icon}
                     alt="GridFabric Icon"
                   />
                 </div>
               </Link>
             </Col>
             <Col
               xs={12}
               className="d-none d-md-block"
               style={{ paddingLeft: "40px" }}
             >
               <OutboundLink
                 style={{ color: "white" }}
                 href="mailto:sales@gridfabric.io"
                 subject="GridFabric Website Inquiry"
               >
                 sales@gridfabric.io
               </OutboundLink>
             </Col>
           </Row>
         </Col>
         <Col md={4} xs={6}>
           <div className={styles.header}>Info</div>
           <div className={styles.link}>
             <Link
               className={styles.link}
               to="/about"
               onClick={() => clickEvent("Footer", "Footer About")}
             >
               About Us
             </Link>
             <Link
               className={styles.link}
               to="/about#contact"
               onClick={() => clickEvent("Footer", "Footer Contact")}
             >
               Contact Us
             </Link>
             <Link
               className={styles.link}
               to="/privacy"
               onClick={() => clickEvent("Footer", "Footer Privacy")}
             >
               Privacy Policy
             </Link>
           </div>
         </Col>
         <Col sm={4} xs={6}>
           <div className={styles.header}>Products</div>
           <div className={styles.link}>
             <Link
               className={styles.link}
               to="/canvas"
               onClick={() => clickEvent("Footer", "Footer Canvas")}
             >
               Canvas{" "}
               <span style={{ fontSize: "0.8em", fontStyle: "italic" }}>
                 server
               </span>
             </Link>
             <Link
               className={styles.link}
               to="/plaid"
               onClick={() => clickEvent("Footer", "Footer Plaid")}
             >
               Plaid{" "}
               <span style={{ fontSize: "0.8em", fontStyle: "italic" }}>
                 client
               </span>
             </Link>
           </div>
         </Col>
       </Row>
     </Container>
   </div>
 )}

export default Footer