export function clickEvent(event_category, event_action, event_label) {
  if (typeof window !== "undefined" && typeof window.gtag === "function") {
    window.gtag("event", "click", {
      event_category,
      event_action,
      event_label,
    })
  }
}

export function conversionEvent(conversionID){
  if ((typeof window !== "undefined") && (typeof window.gtag === "function")) {
    window.gtag("event", "conversion", {
      send_to: conversionID,
    })
  }

}

export function getLocation(){
  if(!window || !window.location || !window.location.pathname) return ""
  return window.location.pathname
}