import React from "react"
import { Navbar, Nav, Button, Container } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { clickEvent } from "../../utils/events"

import styles from './Header.module.css'

const ComplexLink = ({ linkName, linkDesc, link }) => (
  <div>
    <div className="d-none d-lg-block">
      <Nav.Link
        href={link}
        className={styles.complexLink}
        onClick={() => clickEvent(`Navbar`, `Navbar ${linkName}`)}
      >
        <div className={styles.linkName}>{linkName}</div>
        <div className={styles.linkDesc}>{linkDesc}</div>
      </Nav.Link>
    </div>
    <div className="d-block d-lg-none">
      <Nav.Link
        href={link}
        className={styles.complexLinkSmall}
        onClick={() => clickEvent(`Navbar`, `Navbar ${linkName}`)}
      >
        <span className={styles.linkName}>{linkName} &nbsp;</span>
        <span className={styles.linkDesc}>{linkDesc}</span>
      </Nav.Link>
    </div>
  </div>
)

const Header = () => {

  const data = useStaticQuery(graphql`
    query {
      logo: contentfulWebsiteImages(imageId: { eq: "gridfabricLogoSVG" }) {
        image {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Navbar expand="lg">
        <Navbar.Brand className={styles.logo} href="/">
          <img
            src={data.logo.image.file.url}
            alt="GridFabric Logo"
            style={{marginBottom: "0"}}
            width="228"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <ComplexLink
              link="/canvas"
              linkName="Canvas"
              linkDesc="OpenADR VTN"
            />
            <ComplexLink
              link="/plaid"
              linkName="Plaid"
              linkDesc="OpenADR VEN"
            />
          </Nav>
          <Nav>
            <Nav.Link
              href="/about"
              className={styles.navLink}
              onClick={() => clickEvent(`Navbar`, `Navbar About`)}
            >
              About
            </Nav.Link>
            <Nav.Link
              href="/blog"
              className={styles.navLink}
              onClick={() => clickEvent(`Navbar`, `Navbar Blog`)}
            >
              Blog
            </Nav.Link>
          </Nav>
          <Button
            variant="outline-success"
            className="btn-gridfabric-outline"
            href="/about#contact"
            onClick={() => clickEvent(`Navbar`, `Navbar Contact`)}
          >
            Contact Us
          </Button>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default Header
